/* .cart-functions .best-fit-size-chart {
	margin-left: 12px;
} */

div .scr-open-parent,
div .best-fit-size-chart {
	width: 100%;
	max-width: 100%;
}
div button.scr-open-size-chart {
	background: transparent;
	border: 0;
	box-shadow: none;
	padding: 0;
	color: rgb(61, 66, 70);
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 1.3;
	outline: 0;
	font-size: 14px;
	cursor: pointer;
	text-decoration: underline;
	width: auto;
}
div button.scr-open-size-chart:hover {
	opacity: 0.6;
	background: transparent;
	border: 0;
}

/* icons */
div button.scr-open-size-chart::before {
	content: '';
	width: 20px;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: 8px;
}

div button.scr-open-size-chart.none {
	display: inline-block;
}

div button.scr-open-size-chart.none::before {
	content: none;
}

div button.scr-open-size-chart.ruler1::before {
	background: url('https://size-charts-relentless.herokuapp.com/img/ruler1.png') no-repeat 50% / contain;
	height: 15px;
}
div button.scr-open-size-chart.ruler2::before {
	background: url('https://size-charts-relentless.herokuapp.com/img/ruler2.png') no-repeat 50% / contain;
	height: 19px;
}
div button.scr-open-size-chart.ruler3::before {
	background: url('https://size-charts-relentless.herokuapp.com/img/ruler3.png') no-repeat 50% / contain;
	height: 14px;
}
div button.scr-open-size-chart.ruler4::before {
	background: url('https://size-charts-relentless.herokuapp.com/img/ruler4.png') no-repeat 50% / contain;
	height: 17px;
}
div button.scr-open-size-chart.protractor::before {
	background: url('https://size-charts-relentless.herokuapp.com/img/protractor.png') no-repeat 50% / contain;
	height: 17px;
}
div button.scr-open-size-chart.arrows1::before {
	background: url('https://size-charts-relentless.herokuapp.com/img/arrows1.png') no-repeat 50% / contain;
	height: 17px;
}
div button.scr-open-size-chart.arrows2::before {
	background: url('https://size-charts-relentless.herokuapp.com/img/arrows2.png') no-repeat 50% / contain;
	height: 24px;
}

div button.scr-open-size-chart.Medium {
	font-size: 18px;
}
div button.scr-open-size-chart.Medium::before {
	height: 20px;
	width: 26px;
}

div button.scr-open-size-chart.Large {
	font-size: 24px;
}
div button.scr-open-size-chart.Large::before {
	height: 28px;
	width: 36px;
}

/* modal */
.scr-modal {
	transition: visibility 0.4s ease-in, opacity 0.4s ease-in, transform 0.4s ease-in;
	opacity: 0;
	visibility: hidden;

	color: black;
	background: white;
	padding: 20px;
	padding-top: 0;
	overflow-y: auto;
	max-height: calc(100vh - 70px);
	width: calc(100% - 30px);
	max-width: 700px;

	position: fixed;
	top: 50px;
	left: 50%;
	transform: translate(-50%, 0%) scale(0.5);
	z-index: 9999100109;
}
.scr-modal .scr-close {
	width: 16px;
	height: 16px;
	opacity: 0.3;
	cursor: pointer;
	display: flex;
	padding: 23px;
	margin: 7px -16px -7px auto;
	position: relative;
	transform: translate(0, 0); /* no idea */
}
.scr-modal .scr-close:hover {
	opacity: 1;
}
.scr-modal .scr-close:before, 
.scr-modal .scr-close:after {
	content: '';
	position: absolute;
	top: 15px;
	right: 23px;
	height: 17px;
	width: 2px;
	background-color: #333;
}
.scr-modal .scr-close:before {
	transform: rotate(45deg);
}
.scr-modal .scr-close:after {
	transform: rotate(-45deg);
}

.scr-shadow {
	transition: visibility 0.8s ease, opacity 0.8s ease;
	opacity: 0;
	visibility: hidden;

	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
	z-index: 9999100105;
}

.scr {
	margin-bottom: 30px;
}
.scr:last-child {
	margin-bottom: 0;
}

.scr h2 {
	text-align: center;
	font-size: 20px;
}

.scr h2,
.scr .top-description,
.scr table,
.scr .bottom-description {
	margin-top: 0;
	margin-bottom: 20px;
}

.scr .top-description p,
.scr .bottom-description p {
	margin-bottom: 3px;
	line-height: 1.4;
}

.scr > :last-child {
	margin-bottom: 0;
}

.scr table {
	width: 100%;
	display: table;
	border-collapse: collapse;
}

.scr tr {
	display: table-row;
}

.scr table td {
	display: table-cell;
	border: 0;
	line-height: 1.2;
	padding: 13px;
	font-size: 16px;
	font-family: 'Segoe UI', Arial, Helvetica, sans-serif;
}

.scr table tr:before,
.scr table tr:after,
.scr table td:before,
.scr table td:after {
	content: none !important;
}

/* basic */
.scr.basic table {
	border: 1px solid #e7e7e7;
}
.scr.basic table tr td {
	background: #f6f6f6;
	border: 1px solid #eaeaea;
}
.scr.basic table tr:first-child td {
	background: linear-gradient(to bottom,#f8f8f8,#eee);
	border: 0;
	font-weight: bold;
}
.scr.basic table tr:nth-child(2) td {
	border-top: 1px solid #ddd;
}
.scr.basic table tr:nth-child(2n + 3) td {
	background: #fff;
}
.scr.basic table td {
	text-align: left;
}

/* dark */
.scr.dark table {
	color: white;
	border: 0;
}
.scr.dark table tr:first-child {
	border-bottom: 1px solid #333;
}
.scr.dark table tr td {
	background: #1b2224;
}
.scr.dark table tr td::selection {
	background: gray;
}
.scr.dark table tr:nth-child(2n + 3) td {
	background: #263033;
}

.best-fit-size-chart .scr-open-size-chart font {
	pointer-events: none;
}

.scr-modal img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 768px) {
	.scr-modal {
		max-height: calc(100vh - 70px);
	}

	.scr h2 {
		font-size: 18px;
	}
	.scr table td {
		font-size: 14px;
	}
}

@media (max-width: 600px) {
	.scr-modal img {
		display: block;
		margin-left: auto !important;
		margin-right: auto !important;
		float: none !important;
	}

	.scr table td {
		padding: 13px 2px;
	}
}

@media (max-width: 500px) {
	.scr h2 {
		font-size: 16px;
	}
	.scr table td {
		font-size: 9px;
	}
}
