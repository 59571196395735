div .scr-open-parent, div .best-fit-size-chart {
  width: 100%;
  max-width: 100%;
}

div button.scr-open-size-chart {
  box-shadow: none;
  color: #3d4246;
  cursor: pointer;
  width: auto;
  background: none;
  border: 0;
  outline: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.3;
  text-decoration: underline;
}

div button.scr-open-size-chart:hover {
  opacity: .6;
  background: none;
  border: 0;
}

div button.scr-open-size-chart:before {
  content: "";
  width: 20px;
  vertical-align: text-bottom;
  margin-right: 8px;
  display: inline-block;
}

div button.scr-open-size-chart.none {
  display: inline-block;
}

div button.scr-open-size-chart.none:before {
  content: none;
}

div button.scr-open-size-chart.ruler1:before {
  height: 15px;
  background: url("https://size-charts-relentless.herokuapp.com/img/ruler1.png") 50% / contain no-repeat;
}

div button.scr-open-size-chart.ruler2:before {
  height: 19px;
  background: url("https://size-charts-relentless.herokuapp.com/img/ruler2.png") 50% / contain no-repeat;
}

div button.scr-open-size-chart.ruler3:before {
  height: 14px;
  background: url("https://size-charts-relentless.herokuapp.com/img/ruler3.png") 50% / contain no-repeat;
}

div button.scr-open-size-chart.ruler4:before {
  height: 17px;
  background: url("https://size-charts-relentless.herokuapp.com/img/ruler4.png") 50% / contain no-repeat;
}

div button.scr-open-size-chart.protractor:before {
  height: 17px;
  background: url("https://size-charts-relentless.herokuapp.com/img/protractor.png") 50% / contain no-repeat;
}

div button.scr-open-size-chart.arrows1:before {
  height: 17px;
  background: url("https://size-charts-relentless.herokuapp.com/img/arrows1.png") 50% / contain no-repeat;
}

div button.scr-open-size-chart.arrows2:before {
  height: 24px;
  background: url("https://size-charts-relentless.herokuapp.com/img/arrows2.png") 50% / contain no-repeat;
}

div button.scr-open-size-chart.Medium {
  font-size: 18px;
}

div button.scr-open-size-chart.Medium:before {
  height: 20px;
  width: 26px;
}

div button.scr-open-size-chart.Large {
  font-size: 24px;
}

div button.scr-open-size-chart.Large:before {
  height: 28px;
  width: 36px;
}

.scr-modal {
  opacity: 0;
  visibility: hidden;
  color: #000;
  max-height: calc(100vh - 70px);
  width: calc(100% - 30px);
  max-width: 700px;
  z-index: 2147483647;
  background: #fff;
  padding: 0 20px 20px;
  transition: visibility .4s ease-in, opacity .4s ease-in, transform .4s ease-in;
  position: fixed;
  top: 50px;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%)scale(.5);
}

.scr-modal .scr-close {
  width: 16px;
  height: 16px;
  opacity: .3;
  cursor: pointer;
  margin: 7px -16px -7px auto;
  padding: 23px;
  display: flex;
  position: relative;
  transform: translate(0);
}

.scr-modal .scr-close:hover {
  opacity: 1;
}

.scr-modal .scr-close:before, .scr-modal .scr-close:after {
  content: "";
  height: 17px;
  width: 2px;
  background-color: #333;
  position: absolute;
  top: 15px;
  right: 23px;
}

.scr-modal .scr-close:before {
  transform: rotate(45deg);
}

.scr-modal .scr-close:after {
  transform: rotate(-45deg);
}

.scr-shadow {
  opacity: 0;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background: #00000080;
  transition: visibility .8s, opacity .8s;
  position: fixed;
  top: 0;
  left: 0;
}

.scr {
  margin-bottom: 30px;
}

.scr:last-child {
  margin-bottom: 0;
}

.scr h2 {
  text-align: center;
  font-size: 20px;
}

.scr h2, .scr .top-description, .scr table, .scr .bottom-description {
  margin-top: 0;
  margin-bottom: 20px;
}

.scr .top-description p, .scr .bottom-description p {
  margin-bottom: 3px;
  line-height: 1.4;
}

.scr > :last-child {
  margin-bottom: 0;
}

.scr table {
  width: 100%;
  border-collapse: collapse;
  display: table;
}

.scr tr {
  display: table-row;
}

.scr table td {
  border: 0;
  padding: 13px;
  font-family: Segoe UI, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  display: table-cell;
}

.scr table tr:before, .scr table tr:after, .scr table td:before, .scr table td:after {
  content: none !important;
}

.scr.basic table {
  border: 1px solid #e7e7e7;
}

.scr.basic table tr td {
  background: #f6f6f6;
  border: 1px solid #eaeaea;
}

.scr.basic table tr:first-child td {
  background: linear-gradient(#f8f8f8, #eee);
  border: 0;
  font-weight: bold;
}

.scr.basic table tr:nth-child(2) td {
  border-top: 1px solid #ddd;
}

.scr.basic table tr:nth-child(2n+3) td {
  background: #fff;
}

.scr.basic table td {
  text-align: left;
}

.scr.dark table {
  color: #fff;
  border: 0;
}

.scr.dark table tr:first-child {
  border-bottom: 1px solid #333;
}

.scr.dark table tr td {
  background: #1b2224;
}

.scr.dark table tr td::selection {
  background: gray;
}

.scr.dark table tr:nth-child(2n+3) td {
  background: #263033;
}

.best-fit-size-chart .scr-open-size-chart font {
  pointer-events: none;
}

.scr-modal img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .scr-modal {
    max-height: calc(100vh - 70px);
  }

  .scr h2 {
    font-size: 18px;
  }

  .scr table td {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .scr-modal img {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .scr table td {
    padding: 13px 2px;
  }
}

@media (max-width: 500px) {
  .scr h2 {
    font-size: 16px;
  }

  .scr table td {
    font-size: 9px;
  }
}

/*# sourceMappingURL=index.4c3886c2.css.map */
